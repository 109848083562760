import React from "react";
import Avatar from "../assets/Avatar.jpeg";
import Typewriter from "typewriter-effect";

function Headers() {
  return (
    <div>
      <div className="md:p-12 bg-gradient-to-r from-colour-0 to-colour-1 body-font h-screen relative">
        <div className="flex flex-col md:flex-row-reverse justify-center items-center md:h-full">

          <div className="mt-40 md:mt-0 mb-20 md:mb-0 w-1/2" bis_skin_checked="1">
            <img alt="content" className="md:h-[16rem] lg:h-[28rem] rounded-full mx-auto" src={Avatar} />
          </div>

          <div className="md:w-1/2" bis_skin_checked="1">
            <div className="text-3xl lg:text-7xl text-right">
              <h1 className="text-white font-bold">Mohammad Umair</h1>
            </div>

            <div className="mt-3 flex justify-center ">
              <div className="text-white">
                <Typewriter
                  options={{
                    strings: [
                      "Frontend Developer",
                      "Backend Developer",
                      "Full Stack Developer",
                    ],
                    autoStart: true,
                    loop: true,
                    wrapperClassName: "text-md text-xl md:text-3xl lg:text-3xl font-mono",
                    cursorClassName: "text-md  text-xl md:text-3xl lg:text-3xl",
                  }}
                />
              </div>
            </div>
          </div>
        <div className="flex bottom-28 md:bottom-12 absolute">
          <div className="ml-6 w-10 h-10 animate-bounce text-white text-5xl md:text-3xl" ><a href="#DEV">&darr;</a></div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Headers;
