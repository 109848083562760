import React, { useState, useEffect, useRef } from "react";
import ProjectList from './ProjectList'
import carRentalImage from "../assets/carrental.png";
import weatherImage from "../assets/Weather.png";
import TaskManager from "../assets/TaskmanagerApp.png";

function Project() {
  const [selectProj, setSelectProj] = useState(-1)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const divRef = useRef(null);
  const projectsArray = [
    {
      name: 'CAR RENTALS',
      bgColor: 0,
      image: carRentalImage,
      demoLink: 'https://car-rentals-mpxc7fo5z-mdumair0.vercel.app/',
      gitHubLink: 'https://github.com/mdumair0/Car_Rentals',
      description: 'The car rental website is an online platform that allows users to rent cars for personal or business use. The website provides an interface for searching, comparing, and reserving cars.'
    },
    {
      name: 'TASK MANAGER',
      bgColor: 4,
      image: TaskManager,
      demoLink: '',
      gitHubLink: 'https://github.com/mdumair0/tasks-manager',
      description: 'The task manager app is a productivity tool that allows users to effectively manage their tasks and track their progress. In this app, users can create, retrieve, update, and delete tasks along with their status.'
    },
    {
      name: 'WEATHER APP',
      bgColor: 2,
      image: weatherImage,
      demoLink: 'https://weather-node-ny1d9ot3w-maircoding.vercel.app/',
      gitHubLink: 'https://github.com/mdumair0/Weather-node-app',
      description: 'The weather app allows users to easily access current weather information by simply entering the name of a city. Key features of this app'
    },
  ]

  useEffect(() => {
    function handleTouchStart(e) {
      if (divRef.current && !divRef.current.contains(e.target)) {
        setSelectProj(-1)
      }
    }

    document.addEventListener('touchstart', handleTouchStart);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkScreenSize();

    // Listen for window resize
    window.addEventListener('resize', checkScreenSize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div>
      <div className="flex justify-center pb-8 md:m-0 font-bold text-3xl md:text-4xl lg:text-6xl">
        PROJECTS
      </div>
      <div ref={divRef} className="flex flex-wrap transition duration-2s transition-all duration-500">
        {projectsArray.map((ele, it) => <ProjectList props={ele} key={it} proj={[selectProj, setSelectProj, it, isSmallScreen]} />)}
      </div>
    </div>
  );
}

export default Project;
