import React from "react";
import { useRef } from "react";
import expressImage from "../assets/express.svg";
import nodejsImage from "../assets/nodejs.svg";
import mongodbImage from "../assets/mongodb.svg";
import reactImage from "../assets/react.svg";

function SkillImage({ image, alt }) {
  const ref = useRef(null);

  return (
    <img ref={ref} src={image} alt={alt}
      className={`mt-6 w-4/6 hover:scale-125 transform transition-transform`}
    />
  );
}

function MernDev() {
  return (
    <div id="DEV" className="flex flex-col justify-center h-[51rem] md:p-24 md:h-screen lg:h-screen">
      <div className="flex justify-center m-5 md:m-0 font-bold text-3xl md:text-4xl lg:text-6xl">
        MERN Stack Developer
      </div>
      <div className="flex justify-center lg:m-5">
        <div className="grid grid-cols-2 lg:p-4 md:gap-8 md:grid-cols-4 lg:grid-cols-4 justify-items-center">
          <SkillImage image={mongodbImage} alt="MongoDB" />
          <SkillImage image={expressImage} alt="Express" />
          <SkillImage image={reactImage} alt="React" />
          <SkillImage image={nodejsImage} alt="Node.js" />
        </div>
      </div>
      <div className="flex justify-center lg:m-5">
        <p className="text-center text lg:text-3xl w-4/6">
          With over 2+ years of experience as a full stack developer, I have worked
          on amazing projects with clients and businesses to develop high
          quality websites and web systems. I create successful websites that
          are fast, easy to use, and built with best practices.
        </p>
      </div>
    </div>
  );
}

export default MernDev;
