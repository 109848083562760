import './App.css';
import Headers from './components/Headers';
import MernDev from './components/MernDev';
import Skills from './components/Skills';
import PaymentPortal from './components/PaymentPortal';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Projects from './components/Projects';

function App() {
  return (
    <div className="App">
      <Headers />
      <MernDev />
      <Projects />
      <PaymentPortal />
      <Skills />
      <Experience />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
