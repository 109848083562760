import React from "react";
import payment from "../assets/payment.svg";

function PaymentPortal() {
  return (
    <div>
      <div className="md:px-10 bg-colour-3 body-font lg:h-[34rem] relative">
        <div className="m-8 mt-0 flex flex-col justify-center items-center lg:h-full " bis_skin_checked="1">
          <div className="mt-16 md:mt-12 lg:mt-20 text-3xl lg:text-7xl " bis_skin_checked="1">
            <h1 className="font-bold text-center font-sans sm:text-2xl md:text-3xl lg:text-6xl text-[#643843]">Finance Portal Development</h1>
          </div>
          <div className="m-4 flex flex-col md:flex-row-reverse justify-center items-center lg:h-full " bis_skin_checked="1">
            <div className='m-5 overflow-hidden w-[17rem] md:w-[30rem] lg:h-[rem] mx-auto'>
              <img className="bg-colour-2 rounded-full" src={payment} alt="Payment Portal" />
            </div>

            <div className="w-full lg:w-1/2 flex justify-center items-center lg:pl-10 my-10 lg:mt-0 mb-5 lg:mb-0">
              <p className="text-xl md:text-xl lg:text-2xl text-center text-[#99627A] lg:text-left">
                I've built robust business logic, Developed Lambda Functions, Created DynamoDB tables, Integrated OpenAPI specifications. I focused on ensuring seamless operations and optimal performance. I successfully utilized Terraform, ECR and ECS for scalable deployment of containerized applications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentPortal;
