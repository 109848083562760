import React from "react";

function MernDev() {
  return (
    <div id="DEV" className="flex md:flex-col bg-colour-4 justify-center">
      <div className="flex  m-5 md:m-0 md:mx-24 md:my-8 md:px-6 font-bold text-white text-sm md:text-xl">
      Copyright © 2023. All rights are reserved
      </div>
    </div>
  );
}

export default MernDev;
