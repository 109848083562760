import React from "react";
import Insta from "../assets/instagram.svg";
import Linkedin from "../assets/linkedin.svg";
import Github from "../assets/Github.svg";
import Mail from "../assets/Mail.svg";


function Contact() {
  return (
    <div id="Contact" className="flex flex-col justify-center h-[18rem] md:h-[30rem] md:p-24 ">
      <div className="flex mx-5 font-bold text-l md:text-2xl lg:text-2xl">
        WAYS TO REACH ME
      </div>
      <div className="md:pt-4 mt-2 flex mx-5 font-bold text-2xl md:text-3xl lg:text-5xl">
        Don't be shy! Hit me up! 👇
      </div>

      <div className="flex mx-2 md:mx-0 my-5 md:my-16">
        <a href="https://www.linkedin.com/in/mohammad-umair-152625145/" target="_blank" rel="noopener noreferrer">
          <div className="md:pt-4 flex mx-2 md:mx-5 p-5 w-20 h-full rounded-full shadow-xl">
            <img alt="connect" src={Linkedin} />
          </div>
        </a>

        <a href="https://github.com/maircoding" target="_blank" rel="noopener noreferrer">
          <div className="md:pt-4 flex mx-2 md:mx-5 p-5 w-20 h-20 rounded-full shadow-xl">
            <img alt="connect" src={Github} />
          </div>
        </a>
        
        <a href="mailto:mohd.umair2325@gmail.com" target="_blank" rel="noopener noreferrer">
        <div className="md:pt-4 flex mx-2 md:mx-5 p-5 w-20 rounded-full shadow-xl">
           <img alt="connect" src={Mail} />
        </div>
        </a>
        <a href="https://www.instagram.com/md_u.m.a.i.r/" target="_blank" rel="noopener noreferrer">
          <div className="md:pt-4 flex mx-2 md:mx-5 p-5 w-20 rounded-full shadow-xl">
            <img alt="connect" src={Insta} />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Contact;
