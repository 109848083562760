import React from "react";

function Project({props, proj}) {
  const {name, bgColor, image, demoLink, gitHubLink, description} = props
  const [selectProj, setSelectProj, it, isSmallScreen] = proj

  let hidden = ''
  if(selectProj === it){
    hidden = ''
  } else{
    hidden = 'hidden'
  }
  
  let baseClass = `bg-colour-${bgColor} h-[40rem]`
  return (
    <div className={`${selectProj === it ? 'flex': 'flex-1'} ${baseClass} relative`} 
    {...(!isSmallScreen && {onMouseEnter: ()=>{setTimeout(()=>{setSelectProj(it)},0)}, onMouseLeave:()=>setSelectProj(-1)})}
    {...(isSmallScreen && {onTouchStart: ()=>{setTimeout(()=>{setSelectProj(it)},300)}, onTouchEnd: ()=>{setTimeout(()=>{setSelectProj(it)},200)}})}
    >
      <div className={`absolute inset-0 bg-cover ${name === 'WEATHER APP' ? 'custom-bg-image': ''}`} style={{ backgroundImage: `url(${image})`, opacity:0.08  }}></div>
      <div className={`flex items-center h-[40rem] justify-${selectProj === it ? 'left':'center'} transition-all duration-500`}>
        <div className="mx-2 md:mx-[2rem] text-white text-2xl">
          {name.split('').map(ele => {
            if(ele === ' ') {
              return <span className="block writing-mode-vertical-lr">&nbsp;</span>
            }
            return <span className="text-center block writing-mode-vertical-lr">{ele}</span>
          })}
        </div>
        <div className={`${hidden} flex flex-col bg-colour-3 w-full h-[40rem]`}>
          <div className={`p-2 md:p-8 ${isSmallScreen ? 'py-12' : 'py-32'} bg-colour-${bgColor} h-1/3 md:h-1/2`}>
            <img className="w-[16rem] md:w-[36rem] relative rounded shadow-lg" alt="" src={image} />
          </div>
          <div className={`md:py-16 bg-colour-${bgColor} h-2/3 md:h-1/2`}>
            <div className="md:mx-8 px-8 w-[16rem] md:w-[38rem] flex text-white justify align-center">
              <p>{description}</p>
            </div>
            <div className="p-12 md:px-12 flex relative flex-col md:flex-row justify align-center">
              {demoLink && <a href={demoLink} target="_blank" rel="noopener noreferrer">
                <div className={`mx-4 w-24 my-2 md:my-8 p-4 bg-colour-0 solid cursor-pointer text-center text-white rounded shadow shadow-colour-0`}>DEMO</div>  
              </a>}
              <a href={gitHubLink} target="_blank" rel="noopener noreferrer">
                <div className={`mx-4 w-24 my-2 md:my-8 p-4 bg-colour-5 text-black font-bold cursor-pointer text-center rounded`}>GITHUB</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
}

export default Project;
