import React from "react";
import awsImage from "../assets/AWS.png"
import cssImage from "../assets/css.svg"
import expressImage from "../assets/express.svg";
import htmlImage from "../assets/html.svg";
import jsImage from "../assets/javascript.svg"
import jqueryImage from "../assets/jquery.svg"
import lessImage from "../assets/less.svg"
import mongodbImage from "../assets/mongodb.svg";
import nodejsImage from "../assets/nodejs.svg";
import npmImage from "../assets/npm.svg"
import reactImage from "../assets/react.svg";
import sassImage from "../assets/sass.svg";
import tailwindImage from "../assets/tailwindcss.svg";
import webpackImage from "../assets/webpack.svg";

function SkillImage({ image, alt }) {
  return (
    <img src={image} alt={alt}
      className={`mt-6 w-4/6 hover:scale-125 transform transition-transform`}
    />
  );
}

function MernDev() {
  return (
    <div className="my-8 py-8 flex flex-col justify-center">
      <div className="flex justify-center m-5 md:m-0 font-bold text-3xl md:text-4xl lg:text-6xl">
        Skills
      </div>
      <div className="flex justify-center m-5">
        <div className="grid grid-cols-2 md:gap-8 md:grid-cols-4 lg:grid-cols-7 justify-items-center">
            <SkillImage image={awsImage} alt="Node.js" />
            <SkillImage image={cssImage} alt="Node.js" />
            <SkillImage image={expressImage} alt="Node.js" />
            <SkillImage image={htmlImage} alt="Node.js" />
            <SkillImage image={jsImage} alt="Node.js" />
            <SkillImage image={jqueryImage} alt="Node.js" />
            <SkillImage image={lessImage} alt="Node.js" />
            <SkillImage image={mongodbImage} alt="Node.js" />
            <SkillImage image={nodejsImage} alt="Node.js" />
            <SkillImage image={npmImage} alt="Node.js" />
            <SkillImage image={reactImage} alt="Node.js" />
            <SkillImage image={sassImage} alt="Node.js" />
            <SkillImage image={tailwindImage} alt="Node.js" />
            <SkillImage image={webpackImage} alt="Node.js" />
        </div>
      </div>
    </div>
  );
}

export default MernDev;
