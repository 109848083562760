import React from "react";


function MernDev() {
  return (
    <div className="pt-12 lg:pt-36 lg:px-16 flex flex-col justify-center bg-colour-0">
      <div className="flex justify-center text-white m-5 md:m-0 font-bold text-3xl md:text-4xl lg:text-6xl">
        Experience
      </div>
      <section>
        <div className="bg-black text-white py-8">
          <div className="lg:container mx-auto flex flex-col items-start md:flex-row lg:my-12 lg:my-24">
            <div className="flex flex-col w-full sticky md:top-36 md:w-2/3 mt-2 md:mt-12 px-8">
              <p className="my-2 text-yellow-300 uppercase tracking-loose">Career Overview</p>
              <p className="my-4 text-3xl md:text-3xl mb-2">WHAT I HAVE DONE SO FAR</p>
              <p className="my-4 text-sm md:text-base text-gray-50 mb-4">
                As a NodeJS backend developer, I have gained valuable experience in developing and maintaining the backend logic and infrastructure of web applications, APIs, and I also had the opportunity to explore serverless architecture extensively over the past two years in the industry.
              </p>
              <a href="#Contact"
                className="my-4 bg-transparent mr-auto hover:bg-yellow-300 text-yellow-300 hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-yellow-300 hover:border-transparent">
                Connect Now</a>
            </div>
            <div className="ml-0 lg:ml-12 lg:w-2/3 sticky">
              <div className="container mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden lg:p-10 h-full">
                  <div className="border-2-2 border-yellow-555 absolute h-full border right-1/2"
                  ></div>
                  <div className="border-2-2 border-yellow-555 absolute h-full border left-1/2"
                  ></div>
                  <div className="m-4 mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12"></div>
                    <div className="order-1 w-5/12 px-1 py-4 text-left">
                      <p className="mb-3 text-base text-yellow-300">August, 2021 - Present</p>
                      <h4 className="mb-3 font-bold text-lg md:text-2xl">Backend Developer</h4>
                      <div className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        <ul className="list-disc">
                          <li>Built Finance Portal for one of the largest automotive manufacturers</li>
                          <li>Built, Updated and improved APIs and scripts ensuring high performance</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12"></div>
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <p className="mb-3 text-base text-yellow-300">Feb - June, 2021</p>
                      <h4 className="mb-3 font-bold text-lg md:text-2xl">Trainee</h4>
                      <ul className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                        <ul className="list-disc">
                          <li>
                          	Trained on Mean stack technologies, such as Node.js, MongoDB, Express, and Angular
                          </li>
                          <li>
                            Built an online Book Store website where user can purchase, add review to the purchased book
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
                <img alt="" className="mx-auto -mt-36 md:-mt-36" src="https://user-images.githubusercontent.com/54521023/116968861-ef21a000-acd2-11eb-95ac-a34b5b490265.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MernDev;
